import React, { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import styles from "./OrderModal.module.scss"
import OrderModalBlock from "./OrderModalBlock"
import { PhotoCameraIcon } from "../../../icons/PhotoCameraIcon"
import clsx from "clsx"
import { useTranslation } from "react-i18next"

interface Props {
  el: any
  setFieldValue: any
  values: any
  isRequired?: boolean
  error?: boolean
  isDesktop: boolean
}

const OrderModalPhotoBlock: React.FC<Props> = ({ el, setFieldValue, values, isRequired, error, isDesktop }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const [isErrorMes, setIsErrorMes] = useState(false)

  const dropzoneAccept = isDesktop
    ? {
        accept: {
          "image/jpeg": [],
          "image/png": [],
        },
      }
    : undefined

  const {
    // acceptedFiles,
    // fileRejections,
    getRootProps,
    getInputProps,
    inputRef,
  }: any = useDropzone({
    noClick: true,
    ...dropzoneAccept,
    onDrop: (acceptedFiles) => {
      const acceptTypes = ["image/jpeg", "image/png"]

      if (!acceptTypes.includes(acceptedFiles[0].type)) {
        setIsErrorMes(true)
      } else {
        setFieldValue(`${inputRef.current.name}`, [
          ...values[inputRef.current.name],
          ...acceptedFiles.map((file: any) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
        ])
      }
    },
  })

  useEffect(() => {
    setIsErrorMes(false)
  }, [values])

  // Превьюшки
  const thumbs = (id: string) =>
    values[id]?.map((file: any) => (
      <div className={styles.photoBlock__preview} key={file.name}>
        <img
          src={file.preview}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
        />
        <button
          type="button"
          onClick={() =>
            setFieldValue(
              id,
              values[id].filter((el: any) => el.path !== file.path),
            )
          }
          className={styles.photoBlock__close}
        >
          <img src="/img/close.svg" alt="" />
        </button>
      </div>
    ))

  return (
    <OrderModalBlock title={`${el?.name}`} isRequired={isRequired}>
      <div className={`${styles.photoBlock}`}>
        {values[el.id]?.length ? thumbs(el.id) : null}
        <label {...getRootProps()} className={`${styles.photoBlock__append} ${error ? `${styles.error} error` : ""}`}>
          <input {...getInputProps()} hidden name={el.id} />
          <PhotoCameraIcon className={styles.photoBlock__camera} />
          <div className={styles.photoBlock__plus}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="add_24px">
                <path
                  id="icon/content/add_24px"
                  d="M12.0002 8.66683H8.66683V12.0002C8.66683 12.3668 8.36683 12.6668 8.00016 12.6668C7.6335 12.6668 7.3335 12.3668 7.3335 12.0002V8.66683H4.00016C3.6335 8.66683 3.3335 8.36683 3.3335 8.00016C3.3335 7.6335 3.6335 7.3335 4.00016 7.3335H7.3335V4.00016C7.3335 3.6335 7.6335 3.3335 8.00016 3.3335C8.36683 3.3335 8.66683 3.6335 8.66683 4.00016V7.3335H12.0002C12.3668 7.3335 12.6668 7.6335 12.6668 8.00016C12.6668 8.36683 12.3668 8.66683 12.0002 8.66683Z"
                />
              </g>
            </svg>
          </div>
        </label>

        {isErrorMes && <span className={clsx(styles["photoBlock__error"], "error-text")}>{t("onlyJpgPng")}</span>}
      </div>
    </OrderModalBlock>
  )
}

export default OrderModalPhotoBlock
